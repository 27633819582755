export function Logo(props) {
	return (
		<svg viewBox="0 0 682.1 280.8"
		     {...props}
		>
			<path fill="#231F20"
			      d="M252.9,170.6l-17.7-21.4h-5.3v21.4h-12.1v-60.5H238c4.7,0,8.7,0.8,12.1,2.5c3.4,1.7,6,4,7.8,6.9
			c1.8,2.9,2.7,6.3,2.7,10.1c0,4.1-1,7.7-3.1,10.8c-2.1,3.1-5,5.4-8.8,7l19.3,23.2H252.9z M229.9,121.4V138h8c3.3,0,5.8-0.8,7.6-2.3
			c1.8-1.6,2.7-3.5,2.7-6c0-2.4-0.9-4.4-2.7-6c-1.8-1.6-4.3-2.3-7.6-2.3H229.9z"
			/>
			<path fill="#231F20"
			      d="M301.2,128.6c3.5,2,6.4,4.6,8.5,8c2.1,3.4,3.2,7.2,3.2,11.4c0,1.6-0.2,3.2-0.5,4.9h-33.7c0.6,3,1.9,5.3,3.9,7
			c2,1.6,4.4,2.5,7.4,2.5c2.3,0,4.2-0.4,5.8-1.3c1.6-0.8,2.9-2.1,3.9-3.8h11.6c-1.7,4.2-4.3,7.6-8,10.3c-3.7,2.7-8.2,4-13.4,4
			c-4,0-7.8-1-11.4-2.9c-3.6-1.9-6.4-4.6-8.6-8.1c-2.1-3.5-3.2-7.5-3.2-12c0-4.5,1.1-8.5,3.2-12c2.1-3.5,5-6.2,8.5-8.2
			c3.5-1.9,7.4-2.9,11.4-2.9C293.9,125.6,297.7,126.6,301.2,128.6z M282.6,137.3c-1.9,1.6-3.2,3.8-3.9,6.7h22.4
			c-0.6-2.7-1.8-4.9-3.7-6.6c-1.9-1.7-4.4-2.5-7.6-2.5C287,135,284.6,135.7,282.6,137.3z"
			/>
			<path fill="#231F20"
			      d="M330.6,168.8c-3.7-1.9-6.6-4.6-8.8-8.1c-2.2-3.5-3.3-7.5-3.3-12c0-4.5,1.1-8.5,3.3-12
			c2.2-3.5,5.1-6.2,8.8-8.2c3.7-1.9,7.6-2.9,11.8-2.9c5.1,0,9.8,1.5,14,4.4c4.2,2.9,7.1,7.1,8.6,12.4h-11.8
			c-1.2-2.1-2.7-3.6-4.6-4.7c-1.8-1-3.9-1.6-6.2-1.6c-3.5,0-6.5,1.2-8.8,3.5c-2.4,2.4-3.5,5.3-3.5,8.9c0,3.5,1.2,6.5,3.5,8.8
			c2.4,2.4,5.3,3.5,8.8,3.5c2.3,0,4.4-0.5,6.2-1.6c1.8-1,3.4-2.6,4.6-4.6H365c-1.5,5.4-4.4,9.5-8.6,12.4c-4.2,2.9-8.9,4.4-14,4.4
			C338.2,171.7,334.2,170.7,330.6,168.8z"
			/>
			<path fill="#231F20"
			      d="M400.1,126.1v10.6h-5.5c-3.1,0-5.6,1-7.8,3.1c-2.1,2.1-3.2,4.8-3.2,8v22.8h-11.5v-44h11.1v4.9
			c3.6-3.7,8.1-5.5,13.4-5.5H400.1z"
			/>
			<path fill="#231F20"
			      d="M416.8,169.1c-3.1-1.7-5.5-4.2-7.1-7.3c-1.7-3.1-2.5-6.8-2.5-10.8v-24.3h11.5v24.3c0,3.1,0.8,5.5,2.5,7.3
			c1.6,1.8,3.8,2.8,6.6,2.8c2.7,0,4.9-0.9,6.6-2.8s2.5-4.3,2.5-7.3v-24.3h11.5v24.3c0,4.1-0.8,7.7-2.5,10.8c-1.7,3.1-4,5.6-7.1,7.3
			c-3.1,1.7-6.7,2.6-10.9,2.6C423.5,171.7,419.9,170.8,416.8,169.1z"
			/>
			<rect x="458.1"
			      y="126.7"
			      fill="#231F20"
			      width="11.5"
			      height="44"
			/>
			<rect x="457.7"
			      y="110.2"
			      fill="#231F20"
			      width="12.2"
			      height="10.4"
			/>
			<path fill="#231F20"
			      d="M506.4,137.3h-10.5v15.9c0,2.1,0.6,3.8,1.8,5c1.2,1.2,2.9,1.8,5,1.8h3.7v10.6h-6.7c-4.8,0-8.5-1.4-11.3-4.1
			c-2.7-2.7-4.1-6.5-4.1-11.2v-18.1h-7.2v-10.6h7.2V115h11.5v11.7h10.5V137.3z"
			/>
			<path fill="#231F20"
			      d="M541.2,139.3l25.8,31.4h-15.3l-22.5-27.7v27.7h-12.1v-60.5h12.1v26.7l21.4-26.7h14.3L541.2,139.3z"
			/>
			<rect x="573.4"
			      y="126.7"
			      fill="#231F20"
			      width="11.5"
			      height="44"
			/>
			<rect x="572.9"
			      y="110.2"
			      fill="#231F20"
			      width="12.2"
			      height="10.4"
			/>
			<path fill="#231F20"
			      d="M621.7,137.3h-10.5v15.9c0,2.1,0.6,3.8,1.8,5c1.2,1.2,2.9,1.8,5,1.8h3.7v10.6H615c-4.8,0-8.5-1.4-11.3-4.1
			c-2.7-2.7-4.1-6.5-4.1-11.2v-18.1h-7.2v-10.6h7.2V115h11.5v11.7h10.5V137.3z"
			/>
			<path fill="#E11D48"
			      d="M139.1,60.5l-37.8,21.8l0,0l-40.8,23.6v45.4v1.7v43.7l40.8,23.6l40.8-23.6v0l37.8-21.8v-43.7v-1.7V84.1
		L139.1,60.5z M66.5,112.8l31.8,18.4V168l-31.8-18.4V112.8z M133.1,107.6L101.3,126l-31.8-18.4l31.8-18.4L133.1,107.6z M104.3,131.2
		l31.8-18.4v36.8L104.3,168V131.2z M142.1,109.4L174,91v36.8l-31.8,18.4V109.4z M139.1,67.4L171,85.8l-31.8,18.4l-31.8-18.4
		L139.1,67.4z M66.5,156.5l31.8,18.4v36.8l-31.8-18.4V156.5z M104.3,211.7v-36.8l31.8-18.4v36.8L104.3,211.7z M174,171.4l-31.8,18.4
		v-36.8v0l31.8-18.4V171.4z"
			/>
		</svg>
	);
}
