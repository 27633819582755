const routes = [
	// {
	// 	title: 'Home',
	// 	links: [
	// 		{
	// 			title: 'Home',
	// 			href:  '/docs',
	// 		},
	// 	],
	// },
	{
		title: 'Team management',
		links: [
			{
				title: 'Overview',
				href:  '/docs/team-management',
			},
			{
				title: 'Roles and permissions',
				href:  '/docs/team-management/roles-and-permissions',
			},
			{
				title: 'Single sign-on',
				href:  '/docs/team-management/single-sign-on',
			},
			// {
			// 	title: 'Automatic provisioning',
			// 	href:  '/docs/team-management/scim',
			// },
		],
	},
	// {
	// 	title: 'Testing',
	// 	links: [
	// 		{
	// 			title: 'Supported markdown elements',
	// 			href:  '/docs/testing/supported-markdown-elements',
	// 		},
	// 	],
	// },
];

export default routes;