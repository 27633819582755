import {Container}         from '@/domains/marketing/components/Container';
import {StripeClimateIcon} from '@/domains/shared/components/icons/StripeClimateIcon';
import {Logo}              from '@/domains/shared/components/Logo';
import Link                from 'next/link';

const navigation = {
	features: [
		{
			name: 'Registration *',
			href: '/features/registration',
		},
		{
			name: 'Screening *',
			href: '/features/screening',
		},
	],
	product:   [
		{
			name: 'Pricing *',
			href: '/pricing',
		},
		// TODO: Move to features section once enterprise and changelog are implemented
		{
			name: 'Integrations *',
			href: '/features/integrations',
		},
		// {
		// 	name: 'Enterprise *',
		// 	href: '/enterprise',
		// },
		// {
		// 	name: 'Changelog *',
		// 	href: '/changelog',
		// },
	],
	support:   [
		{
			name: 'Docs',
			href: '/docs',
		},
		{
			name: 'Contact us',
			href: '/contact-us',
		},
		{
			name: 'System status',
			href: 'https://status.recruitkit.com.au/',
		},
	],
	company:   [
		// {
		// 	name: 'About us *',
		// 	href: '/about',
		// },
		{
			name: 'Blog',
			href: '/blog',
		},
		{
			name: 'Social good',
			href: '/blog/climate-change',
		},
	],
	terms:     [
		{
			name: 'Privacy',
			href: 'https://legal.recruitkit.com.au/policies/privacy-policy',
		},
		{
			name: 'Terms',
			href: 'https://legal.recruitkit.com.au/policies/terms-and-conditions',
		},
	],
	social:    [
		{
			name: 'LinkedIn',
			href: 'https://www.linkedin.com/company/recruitkit/',
			icon: (props) => (<svg fill="currentColor"
			                       viewBox="0 0 24 24" {...props}>
				<path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
			</svg>),
		},
	],
};

export function Footer({
	                       stripeClimate = false,
	                       newsletter = false,
                       }) {
	return (<footer className="bg-gray-50"
	                aria-labelledby="footer-heading"
	>
		<h2 id="footer-heading"
		    className="sr-only"
		>
			Footer
		</h2>

		<Container className="pb-8 pt-16 lg:pt-16 space-y-12 divide-y divide-gray-900/10">
			<div className="xl:grid xl:grid-cols-3 xl:gap-8">
				<div className="space-y-2">
					<Link href="/">
						<Logo className="h-20" />
					</Link>

					<p className="max-w-sm text-sm leading-6 text-gray-600">
						Helping employers scale efficiently through automation.
					</p>

					{stripeClimate && <a href="https://climate.stripe.com/nozQls"
					                     target="_blank"
					                     rel="noreferrer"
					                     className="inset-0 text-sm text-gray-700 leading-6 hover:text-gray-700 flex items-center space-x-4 md:max-w-xs mt-4 md:mt-0 lg:mt-4 lg:justify-start lg:max-w-none px-4 md:px-0"
					>
						<StripeClimateIcon className="w-12 h-auto" />

						<p className="lg:pr-8 text-balance">
							We contribute 1% of our revenue to remove CO<sub>2</sub> from the atmosphere.
						</p>
					</a>}
				</div>

				<div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
					<div className="md:grid md:grid-cols-2 md:gap-8">
						{false && <div>
							<h3 className="text-sm font-medium leading-6 text-gray-900">Features</h3>
							<ul role="list"
							    className="mt-2 space-y-2"
							>
								{navigation.features.map((item) => (<li key={item.name}>
									<Link href={item.href}
									      className="text-sm leading-6 text-gray-700 hover:text-rose-600 focus:text-rose-600"
									>
										{item.name}
									</Link>
								</li>))}
							</ul>
						</div>}
						{false && <div className="mt-10 md:mt-0">
							<h3 className="text-sm font-medium leading-6 text-gray-900">Product</h3>
							<ul role="list"
							    className="mt-2 space-y-2"
							>
								{navigation.product.map((item) => (<li key={item.name}>
									<Link href={item.href}
									      className="text-sm leading-6 text-gray-700 hover:text-rose-600 focus:text-rose-600"
									>
										{item.name}
									</Link>
								</li>))}
							</ul>
						</div>}
					</div>
					<div className="md:grid md:grid-cols-2 md:gap-8">
						<div>
							<h3 className="text-sm font-medium leading-6 text-gray-900">Support</h3>
							<ul role="list"
							    className="mt-2 space-y-2"
							>
								{navigation.support.map((item) => (<li key={item.name}>
									<Link href={item.href}
									      className="text-sm leading-6 text-gray-700 hover:text-rose-600 focus:text-rose-600"
									>
										{item.name}
									</Link>
								</li>))}
							</ul>
						</div>
						<div className="mt-10 md:mt-0">
							<h3 className="text-sm font-medium leading-6 text-gray-900">Company</h3>
							<ul role="list"
							    className="mt-2 space-y-2"
							>
								{navigation.company.map((item) => (<li key={item.name}>
									<Link href={item.href}
									      className="text-sm leading-6 text-gray-700 hover:text-rose-600 focus:text-rose-600"
									>
										{item.name}
									</Link>
								</li>))}
							</ul>
						</div>
					</div>
				</div>
			</div>

			{newsletter && <div className="pt-8 lg:flex lg:items-center lg:justify-between">
				<div>
					<h3 className="text-sm font-semibold leading-6 text-gray-900">Subscribe to our newsletter</h3>

					<p className="mt-2 text-sm leading-6 text-gray-700">
						The latest news, articles, and resources, sent to your inbox weekly.
					</p>
				</div>

				<form className="mt-6 sm:flex sm:max-w-md lg:mt-0">
					<label htmlFor="email-address"
					       className="sr-only"
					>
						Email address
					</label>
					<input
						type="email"
						name="email-address"
						id="email-address"
						autoComplete="email"
						required
						className="w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:w-56 sm:text-sm sm:leading-6"
						placeholder="Enter your email"
					/>
					<div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
						<button
							type="submit"
							className="flex w-full items-center justify-center rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
						>
							Subscribe
						</button>
					</div>
				</form>
			</div>}

			<div className="pt-8 md:flex md:items-center md:justify-between">
				<div className="flex space-x-6 md:order-2">
					{navigation.social.map((item) => (<a key={item.name}
					                                     href={item.href}
					                                     target="_blank"
					                                     rel="noreferrer"
					                                     className="text-gray-700 hover:text-rose-600 focus:text-rose-600"
					>
						<span className="sr-only">{item.name}</span>

						<item.icon className="h-6 w-6"
						           aria-hidden="true"
						/>
					</a>))}
				</div>

				<div className="mt-8 md:order-1 md:mt-0 flex gap-3 flex-wrap">
					<p className="text-sm leading-6 text-gray-700">
						&copy; {new Date().getFullYear()} RecruitKit Pty Ltd. All rights reserved.
					</p>

					{navigation.terms.map((item) => (<a key={item.name}
					                                    href={item.href}
					                                    target="_blank"
					                                    rel="noreferrer"
					                                    className="text-sm leading-6 text-gray-700 hover:text-rose-600 focus:text-rose-600"
					>
						{item.name}
					</a>))}
				</div>
			</div>
		</Container>
	</footer>);
}
