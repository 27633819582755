import {Container}       from '@/domains/marketing/components/Container';
import {SavvyCalButton}  from '@/domains/marketing/components/SavvyCalButton';
import {Logo}            from '@/domains/shared/components/Logo';
import {
	Popover,
	Transition,
}                        from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {
	ChatBubbleLeftRightIcon,
	DocumentTextIcon,
	LifebuoyIcon,
	QueueListIcon,
	SquaresPlusIcon,
	UsersIcon,
}                        from '@heroicons/react/24/outline';
import classNames        from 'classnames';
import clsx              from 'clsx';
import Link              from 'next/link';
import {Fragment}        from 'react';

function NavLink({
	                 href,
	                 children,
                 }) {
	return (<Link
		href={href}
		className="inline-block rounded-lg px-2 py-1 text-sm font-semibold leading-6 text-slate-700 hover:text-rose-600 focus:text-rose-600"
	>
		{children}
	</Link>);
}

function FlyoutNavLink({
	                       title,
	                       children,
                       }) {
	return (<Popover className="relative">
		<Popover.Button className="inline-flex items-center gap-x-1 rounded-lg px-2 py-1 text-sm font-semibold leading-6 text-slate-700 hover:text-rose-600 focus:text-rose-600">
			<span>{title}</span>
			<ChevronDownIcon className="h-5 w-5"
			                 aria-hidden="true"
			/>
		</Popover.Button>

		<Transition
			as={Fragment}
			enter="transition ease-out duration-200"
			enterFrom="opacity-0 translate-y-1"
			enterTo="opacity-100 translate-y-0"
			leave="transition ease-in duration-150"
			leaveFrom="opacity-100 translate-y-0"
			leaveTo="opacity-0 translate-y-1"
		>
			<Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
				<div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
					{children}
				</div>
			</Popover.Panel>
		</Transition>
	</Popover>);
}

function MobileNavLink({
	                       href,
	                       children,
                       }) {
	return (<Popover.Button as={Link}
	                        href={href}
	                        className="block w-full p-2"
	>
		{children}
	</Popover.Button>);
}

function MobileNavIcon({open}) {
	return (<svg
		aria-hidden="true"
		className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
		fill="none"
		strokeWidth={2}
		strokeLinecap="round"
	>
		<path
			d="M0 1H14M0 7H14M0 13H14"
			className={clsx('origin-center transition', open && 'scale-90 opacity-0')}
		/>
		<path
			d="M2 2L12 12M12 2L2 12"
			className={clsx('origin-center transition', !open && 'scale-90 opacity-0')}
		/>
	</svg>);
}

function MobileNavigation() {
	return (<Popover>
		<Popover.Button
			className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
			aria-label="Toggle Navigation"
		>
			{({open}) => <MobileNavIcon open={open} />}
		</Popover.Button>
		<Transition.Root>
			<Transition.Child
				as={Fragment}
				enter="duration-150 ease-out"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="duration-150 ease-in"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
			</Transition.Child>
			<Transition.Child
				as={Fragment}
				enter="duration-150 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<Popover.Panel
					as="div"
					className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
				>
					{/*<MobileNavLink href="/features">Features</MobileNavLink>*/}
					{/*<MobileNavLink href="/industries">Industries</MobileNavLink>*/}
					{/*<MobileNavLink href="/integrations">Integrations</MobileNavLink>*/}
					{/*<MobileNavLink href="/pricing">Pricing</MobileNavLink>*/}
					<MobileNavLink href="/blog">Blog</MobileNavLink>
					<MobileNavLink href="/docs">Docs</MobileNavLink>
					{/*<hr className="m-2 border-slate-300/40" />*/}
					<MobileNavLink href="https://dashboard.recruitkit.com.au/dashboard">Sign in</MobileNavLink>
					<MobileNavLink href="/contact-us">Get started</MobileNavLink>
				</Popover.Panel>
			</Transition.Child>
		</Transition.Root>
	</Popover>);
}

export function Header({
	                       className,
	                       children,
                       }) {
	const features = {
		items: [
			{
				name:        'Registration *',
				description: 'Job seekers independently upload their information',
				href:        '/features/registration',
				icon:        UsersIcon,
			},
			{
				name:        'Screening *',
				description: 'Our automated AI chatbot conducts the initial screening for you to review',
				href:        '/features/screening',
				icon:        ChatBubbleLeftRightIcon,
			},
			{
				name:        'Integrations *',
				description: 'We integrate with your ATS, HRIS, and payroll',
				href:        '/features/integrations',
				icon:        SquaresPlusIcon,
			},
		],

		// callsToAction: [
		// 	{
		// 		name: 'Watch demo',
		// 		href: '#',
		// 		icon: PlayCircleIcon,
		// 	},
		//
		// 	{
		// 		name: 'Contact sales',
		// 		href: '/contact-us',
		// 		icon: PhoneIcon,
		// 	},
		// ],
	};

	const resources = {
		items: [
			// {
			// 	name:        'About us *',
			// 	description: 'Our story so far',
			// 	href:        '/about',
			// 	icon:        GlobeAsiaAustraliaIcon,
			// },
			{
				name:        'Blog',
				description: 'Our latest articles and guides',
				href:        '/blog',
				icon:        QueueListIcon,
			},
			{
				name:        'Docs',
				description: 'Get immediate answers to your questions',
				href:        '/docs',
				icon:        DocumentTextIcon,
			},
			{
				name:        'Contact us',
				description: 'We\'re here to help if you need us',
				href:        '/contact-us',
				icon:        LifebuoyIcon,
			},
			// {
			// 	name:        'Developers *',
			// 	description: 'APIs and webhooks documentation, so you can build your own integration',
			// 	href:        '/docs/developers',
			// 	icon:        CodeBracketIcon,
			// },
		],
	};

	return (<header className={className}>
		<Container className="space-y-3">
			<nav className={classNames([
				'relative z-50 flex justify-between pt-3 pb-3 lg:pb-0',
				children ? '' : 'lg:pb-3',
			])}
			>
				<div className="flex items-center md:gap-x-12">
					<Link href="/"
					      aria-label="Home"
					>
						<Logo className="h-16 w-auto" />
					</Link>
					<div className="hidden md:flex md:gap-x-6">
						{false && <FlyoutNavLink title="Features">
							<div className="p-4">
								{features.items.map((item) => (<div key={item.name}
								                                    className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
								>
									<div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-rose-600">
										<item.icon className="h-6 w-6 text-gray-600 group-hover:text-white"
										           aria-hidden="true"
										/>
									</div>
									<div>
										<a href={item.href}
										   className="font-semibold text-gray-900"
										>
											{item.name}
											<span className="absolute inset-0" />
										</a>
										<p className="mt-1 text-gray-600">{item.description}</p>
									</div>
								</div>))}
							</div>

							{/*<div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">*/}
							{/*	{features.callsToAction.map((item) => (<a*/}
							{/*		key={item.name}*/}
							{/*		href={item.href}*/}
							{/*		className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"*/}
							{/*	>*/}
							{/*		<item.icon className="h-5 w-5 flex-none text-gray-400"*/}
							{/*		           aria-hidden="true"*/}
							{/*		/>*/}
							{/*		{item.name}*/}
							{/*	</a>))}*/}
							{/*</div>*/}
						</FlyoutNavLink>}

						{/*<NavLink href="/pricing">Enterprise</NavLink>*/}

						{false && <NavLink href="/pricing">Pricing *</NavLink>}

						<FlyoutNavLink title="Resources">
							<div className="p-4">
								{resources.items.map((item) => (<div key={item.name}
								                                     className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
								>
									<div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-rose-600">
										<item.icon className="h-6 w-6 text-gray-600 group-hover:text-white"
										           aria-hidden="true"
										/>
									</div>
									<div>
										<a href={item.href}
										   className="font-semibold text-gray-900"
										>
											{item.name}
											<span className="absolute inset-0" />
										</a>
										<p className="mt-1 text-gray-600">{item.description}</p>
									</div>
								</div>))}
							</div>
						</FlyoutNavLink>
					</div>
				</div>

				<div className="flex items-center gap-x-5 md:gap-x-8">
					<div className="hidden md:block">
						<NavLink href="https://dashboard.recruitkit.com.au/dashboard">Sign in</NavLink>
					</div>
					<SavvyCalButton color="rose"
					                className="hidden md:block"
					>
						<span>
							Get started <span className="hidden lg:inline">today</span>
						</span>
					</SavvyCalButton>
					<div className="-mr-1 md:hidden">
						<MobileNavigation />
					</div>
				</div>
			</nav>

			{children}
		</Container>
	</header>);
}
