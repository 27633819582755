import Link from 'next/link';

export default function Header({
	                               categorySlug = null,
	                               categoryName = null,
                               }) {
	return (
		<h2 className="text-3xl font-bold tracking-tight text-gray-900 divide-x-2 divide-rose-600 gap-x-8 inline-flex">
			<Link href="/blog">Blog</Link>

			{categoryName && <Link href={`/blog/category/${categorySlug}`}
			                       className="text-rose-600 pl-8"
			>{categoryName}</Link>}
		</h2>);
}
