import CategoryList       from '@/domains/blog/components/CategoryList';
import Header             from '@/domains/blog/components/Header';
import articlesDatabase   from '@/domains/blog/databases/articles.json';
import authorsDatabase    from '@/domains/blog/databases/authors.json';
import categoriesDatabase from '@/domains/blog/databases/categories.json';
import {Tiled}            from '@/domains/marketing/components/Backgrounds/Tiled';
import {Container}        from '@/domains/marketing/components/Container';
import PrimaryLayout      from '@/domains/marketing/layouts/PrimaryLayout';
import {Prose}            from '@/domains/shared/components/Prose';
import classNames         from 'classnames';
import Image              from 'next/image';


export default function BlogArticleLayout({
	                                          markdoc,
	                                          children,
                                          }) {
	const article = articlesDatabase.find((candidate) => {
		return candidate.file === markdoc.file.path;
	});

	let title    = article?.title;
	let subtitle = article?.subtitle;

	let pageTitle = article?.pageTitle || `${article?.title} - RecruitKit`;

	let description = article?.description;
	let category    = categoriesDatabase[article?.category] ?? null;

	const authors = article?.authors?.map((author) => {
		return authorsDatabase[author];
	}) ?? [];

	return (<PrimaryLayout pageTitle={pageTitle}
	                       description={description}
	                       ogImage={article?.image}
	>
		<Tiled className="absolute inset-0 z-0 h-full w-full -translate-y-28 stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" />

		<Container className="pt-8 isolate min-h-screen pb-16">
			<div className="lg:mx-0 lg:max-w-none grid gap-8">
				<Header categorySlug={category?.slug}
				        categoryName={category?.name}
				/>

				<div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-7">
					<div className="flex flex-col justify-start gap-12 lg:col-span-2 backdrop-blur backdrop-opacity-50 lg:bg-gradient-to-b lg:from-gray-50 lg:to-gray-200 lg:p-8 lg:-ml-8 lg:rounded-4xl">
						<CategoryList categories={Object.values(categoriesDatabase)}
						              currentCategorySlug={article?.category}
						              className="bg-gray-50 p-4 rounded-2xl lg:bg-transparent lg:p-0 lg:rounded-none"
						/>
					</div>

					<div className="lg:col-span-5">
						<article className="z-10 backdrop-blur backdrop-opacity-50 space-y-8">
							{(title || subtitle) && (<header className="mb-9 space-y-1">
								{title && (
									<h1 className="font-display font-bold text-3xl tracking-tight text-slate-900 dark:text-white max-w-5xl text-balance">
										{title}
									</h1>)}

								{subtitle && (<p className="font-display text-lg font-medium text-slate-500">
									{subtitle}
								</p>)}
							</header>)}

							<div className="mt-8 flex justify-between items-center gap-x-4">
								<div className="relative flex items-center gap-x-4">
									<div className="flex -space-x-2 overflow-hidden flex-shrink-0">
										{authors.map((author, index) => {
											return (<Image
												key={index}
												className="inline-block h-10 w-10 rounded-full ring-2 ring-white"
												src={author.avatar}
												width={40}
												height={40}
												alt={author.name}
											/>);
										})}
									</div>

									{authors.length === 1 && <div className="text-sm leading-6">
										<p className="font-semibold text-gray-900">
											<span className="absolute inset-0" />

											{authors[0].name}
										</p>

										<p className="text-gray-600">{authors[0].role}</p>
									</div>}

									{authors.length > 1 && <div className="text-sm leading-6">
										<p className="font-semibold text-gray-900">
											<span className="absolute inset-0" />

											{authors.slice(0, -1)
											        .map((author) => {
												        return author.name;
											        })
											        .join(', ')}
											{` & ${authors[authors.length - 1].name}`}
										</p>
									</div>}
								</div>

								<div className="flex-shrink-0 flex items-center gap-x-4 text-sm">
									<time dateTime={article?.datetime}
									      title={article?.datetime}
									      className="text-gray-700 font-medium"
									>
										{article?.date}
									</time>
								</div>
							</div>

							{article?.image && <Image className={classNames([
								'h-full w-full bg-gray-50 object-cover rounded-4xl',
							])}
							                         src={article?.image}
							                         width={1500}
							                         height={1500}
							                         alt={article?.title}
							                         priority={true}
							/>}

							<Prose className="prose prose-slate lg:text-justify">{children}</Prose>
						</article>
					</div>
				</div>
			</div>
		</Container>
	</PrimaryLayout>);
}
