import {Footer}      from '@/domains/marketing/components/Footer';
import {Header}      from '@/domains/marketing/components/Header';
import classNames    from 'classnames';
import Head          from 'next/head';
import {usePathname} from 'next/navigation';

export default function PrimaryLayout({
	                                      pageTitle,
	                                      description = null,
	                                      ogImage = null,
	                                      className,
	                                      headerClassName,
	                                      children,
                                      }) {
	const pathname = usePathname();

	return (<>
      <Head>
        <title>{pageTitle}</title>

	      {description && <meta name="description"
	                            content={description}
	      />}

	      <meta property="og:url"
	            content={pathname}
	      />

		  <meta property="og:type"
		        content="website"
		  />

		  <meta property="og:title"
		        content={pageTitle}
		  />

	      {description && <meta property="og:description"
	                            content={description}
	      />}

	      <meta property="og:image"
	            content={ogImage ?? '/assets/logos/logo-with-transparent-background.png'}
	      />

	      <meta name="twitter:card"
	            content="summary_large_image"
	      />

		  <meta property="twitter:domain"
		        content="recruitkit.com.au"
		  />

		  <meta property="twitter:url"
		        content={pathname}
		  />

		  <meta name="twitter:title"
		        content={pageTitle}
		  />

	      {description && <meta name="twitter:description"
	                            content={description}
	      />}

	      <meta property="twitter:image"
	            content={ogImage ?? '/assets/logos/logo-with-transparent-background.png'}
	      />
      </Head>

      <Header className={headerClassName} />

      <main className={classNames([
	      'relative isolate min-h-[70vh]',
	      className,
      ])}
      >
	      {children}
      </main>

      <Footer />
    </>);
}
