import classNames from 'classnames';

export function Tiled({
	                      fillClass = 'fill-gray-50',
	                      tileSize = 200,
	                      ...props
                      }) {
	const patternId = `pattern-tiled-${tileSize}`;
	return (<svg aria-hidden="true"
	             {...props}
	>
		<defs>
			<pattern id={patternId}
			         width={tileSize}
			         height={tileSize}
			         x="50%"
			         y={-1}
			         patternUnits="userSpaceOnUse"
			>
				<path d={`M${tileSize / 2} ${tileSize}V.5M.5 .5H${tileSize}`}
				      fill="none"
				/>
			</pattern>
		</defs>

		<svg x="50%"
		     y={-1}
		     className={classNames([
			     'overflow-visible',
			     fillClass,
		     ])}
		>
			<path
				d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
				strokeWidth={0}
			/>
		</svg>

		<rect width="100%"
		      height="100%"
		      strokeWidth={0}
		      fill={`url(#${patternId})`}
		/>
	</svg>);
}