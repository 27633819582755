import {Button} from '@/domains/shared/components/Button';

export function SavvyCalButton({
	                               children,
	                               ...props
                               }) {
	return (<Button type="button"
	                onClick={(e) => {
		                window.SavvyCal('open', {
			                link: 'recruitkit/chat',
		                });
	                }}
	                {...props}>
		{children}
	</Button>);
}
